<template>
    <div>
        <div class="page-content-search">
            <el-form ref="form" :model="search" size="mini" :label-width="this.env.search_label_width">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="用户昵称">
                            <el-input v-model="search.user_nick"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="用户手机号">
                            <el-input v-model="search.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="订单号">
                            <el-input v-model="search.order_no"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item>
                            <el-button icon="el-icon-search" size="mini" type="primary" @click="is_search">搜索
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <el-row>
            <el-col :span="24">
                <el-form :inline="true" size="medium" class="demo-form-inline"
                         :label-width="this.env.label_width">
                    <el-form-item label="活动名称:">
                        <span class="form-span-text">{{ activity_info.activity_title }}</span>
                    </el-form-item>
                    <el-form-item label="活动地址:">
                        <span class="form-span-text">{{ activity_info.county }}{{
                                activity_info.road
                            }}{{ activity_info.address }}</span>
                    </el-form-item>
                    <el-form-item label="活动时间:">
                        <span v-for="item in activity_info.detailed_time" :key="item.index">
                            {{ item.activity_date }}({{ item.activity_time[0] }}至{{ item.activity_time[1] }})<br/>
                        </span>
                    </el-form-item>
                    <el-form-item label="报名人数:">
                        <span class="form-span-text">{{ activity_info.bookings_sum }}/{{ activity_info.quota }}</span>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
        <el-table
                size="medium"
                border
                v-loading="loading"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    prop="user_nick"
                    label="用户名称">
            </el-table-column>
            <el-table-column
                    prop="phone"
                    label="手机号"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="order_no"
                    label="订单号">
            </el-table-column>
            <el-table-column
                    prop="is_sign"
                    label="是否核销">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.is_sign > 0">已签到</el-tag>
                    <el-tag size="mini" v-else type="info">未签到</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="phone"
                    label="是否退款"
                    width="200">
                <template slot-scope="scope">
                    <el-popover
                            v-if="scope.row.refund_state === 1"
                            placement="right"
                            width="240"
                            trigger="click">
                        <div style="line-height: 30px;">
                            <div>用户名称：{{ scope.row.user_nick }}</div>
                            <div>手机号码：{{ scope.row.phone }}</div>
                            <div>订单号：{{ scope.row.order_no }}</div>
                            <div>退款时间：{{ scope.row.refund_time }}</div>
                            <div>退款备注：{{ scope.row.refund_remarks }}</div>
                        </div>
                        <el-tag size="mini" type="danger" slot="reference">有退款</el-tag>
                    </el-popover>
                    <el-tag size="mini" v-else type="info">无退款</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="pay_time"
                    label="支付时间"
                    width="200">
                <template slot-scope="scope">
                    {{ scope.row.pay_time === '1970-01-01 08:00' ? '无' : scope.row.pay_time }}
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="200">
                <template slot-scope="scope">
                    <el-button
                            v-if="is_auth('order.activity.cancel') && scope.row.refund_state===0"
                            @click="order_cancel_tip(scope.row)"
                            type="primary" size="mini">退款
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "OrderList",
    data() {
        return {
            activity_info: {},    // 活动信息
            loading: false,      // 加载状态
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [], // 添加页面数据
            search: {},         // 搜索内容

        }
    },
    // 创建
    created() {
        // this.init()
    },
    methods: {
        // 取消
        order_cancel_tip(order_info) {
            console.log('order_info', order_info)
            let tip = '取消【' + order_info.user_nick + '】的订单';
            let msg = '取消后买家将收到全额退款，请填写原因告知商户和买家';
            // 弹出二次确认
            this.$prompt(msg, tip)
                    .then(({value}) => {
                        this.order_cancel(order_info.order_no, order_info.user_uuid, value)
                    })
                    .catch(() => {
                    });
        },
        order_cancel(order_no, user_uuid, value) {
            let postdata = {
                api_name: "order.activity.cancel",
                token: this.Tool.get_l_cache('token'),
                order_no,
                user_uuid,
                refund_remarks: value,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        init(activity_info) {
            this.activity_info = activity_info
            this.count = 0;
            this.tableData = [];

            this.search = {
                user_nick: '',
                phone: '',
                order_no: '',
            }
            this.is_search();
        },
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取可选教练列表
        getlist() {
            let postdata = {
                api_name: "order.activity.getlist",
                token: this.Tool.get_l_cache('token'),
                activity_uuid: this.activity_info.activity_uuid,
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },

    }
}
</script>

<style scoped>

</style>
